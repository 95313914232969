import React, {useContext, useEffect, useState} from 'react'
import { DarkModeContext } from '../../context/DarkModeContext'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import logo from "../../assets/img/logo.png";
import BienvenidoAdmin from "../../components/administracion/BienvenidoAdmin";
import TituloPagina from "../../components/TituloPagina";
import TablaReporte from '../../components/administracion/TablaReporte'
import { Button, Spinner } from "react-bootstrap";
import { faFileExcel, faTable, faSheetPlastic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReporteMensual = () => {
    const {darkMode}= useContext(DarkModeContext)
    const apiUrlToken=process.env.REACT_APP_API_TOKEN
    const navegacion= useNavigate()
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        const verificarToken = async () => {
          const token = sessionStorage.getItem("token");
    
          if (!token) {
            manejarRespuestaNoAutorizada();
            return;
          }
    
          try {
            const response = await fetch(apiUrlToken, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ Token: token }),
            });
    
            if (response.ok) {
              const data = await response.json();
    
              if (data !== 5) {
                manejarAccesoDenegado();
              }
            } else {
              if (response.status === 401) {
                manejarRespuestaNoAutorizada();
              } else {
                throw new Error("Respuesta no satisfactoria del servidor");
              }
            }
          } catch (error) {
            console.error("Error al validar el token", error);
          }
        };
    
        const redireccionar = () => {
          navegacion("/");
          recargarPagina();
        };
    
        const manejarRespuestaNoAutorizada = () => {
          sessionStorage.removeItem("token"); 
    
          Swal.fire({
            title: "Sesión expirada o token inválido.",
            text: "Inicie sesión nuevamente.",
            imageUrl: logo,
            imageWidth: 100,
            imageHeight: 30,
            imageAlt: "Logo",
            confirmButtonText: "Ok",
            timer: 5000,
            allowOutsideClick: true,
            customClass: {
              container: darkMode
                ? "swal2-container--dark"
                : "swal2-container--light",
              confirmButton: "my-swal-button",
            },
          }).then(redireccionar);
        };
    
        const manejarAccesoDenegado = () => {
          Swal.fire({
            title: "Acceso denegado.",
            text: "No tiene permisos para acceder.",
            imageUrl: logo,
            imageWidth: 100,
            imageHeight: 30,
            imageAlt: "Logo",
            confirmButtonText: "Ok",
            timer: 5000,
            allowOutsideClick: true,
            customClass: {
              container: darkMode
                ? "swal2-container--dark"
                : "swal2-container--light",
              confirmButton: "my-swal-button",
            },
          }).then(redireccionar);
        };
    
        verificarToken();
      }, []);

      function recargarPagina() {
        window.location.reload()
      }
    const descargarExcelMensual = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            console.error("No hay token disponible");
            return;
        }

        setCargando(true);
        try {
            const response = await fetch("/api/excel/excelfacturacion", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ Token: token }),
            });

            if (!response.ok) {
                throw new Error(`Respuesta de la red no fue ok: ${response.status}`);
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "excel_crm_mensual.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error descargando el archivo Excel", error);
        } finally {
            setCargando(false);
        }
    };

    return (
    <div className="d-flex flex-column min-vh-100" translate="no">
        <div className="pt-5">
        <BienvenidoAdmin />
      </div>
      <div>
        <TituloPagina title="Panel de administracion" />
            </div>
            <div>
                <Button
                    className={
                        darkMode
                            ? "d-flex btn-check-modal-dark centrado border-0 fs-14 my-2"
                            : "d-flex btn-check-modal centrado border-0 fs-14 my-2"
                    }
                    onClick={descargarExcelMensual}
                    disabled={cargando}
                >
                    {cargando ? (
                        <Spinner animation="border" role="status"></Spinner>
                    ) : (
                        <div>
                            <h6 className="fs-14 mt-2">
                                <FontAwesomeIcon className="me-4" icon={faFileExcel} />{" "}
                                Descargar Excel Mensual
                            </h6>
                        </div>
                    )}
                </Button>            </div>
      <div>
        <TablaReporte/>
      </div>

      <div className="py-4 mt-auto">
            <Footer />
        </div>
    </div>
  )
}

export default ReporteMensual