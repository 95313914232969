import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  FormControl,
  InputGroup,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
  Modal,
  FormGroup,
  FormLabel,
  Table
} from "react-bootstrap";
import Select from "react-select";
import { DarkModeContext } from "../../context/DarkModeContext";
import { CRMContext } from "../../context/CRMContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faSearch,
  faUndo,
  faEye,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Swal from "sweetalert2";
import "./CRM.css";
const fieldMap = {
  id: "Id",
  sasmutual: "SasMutual",
  estado: "Estado",
  ndeterminal: "NDeTerminal",
  ndecomercio: "NDeComercio",
  nroLocal: "NroLocal",
  legajo: "Legajo",
  nombredefantasia: "NombreDeFantasia",
  domicilio: "Domicilio",
  localidad: "Localidad",
  codigoPostal: "CodigoPostal",
  telefono: "Telefono",
  linkdepago: "LinkDePago",
  rubro: "Rubro",
  rubrosecundario: "RubroSecundario",
  altaahorA12: "AltaAhora12",
  fechadealta: "FechaDeAlta",
  fechadebaja: "FechaDeBaja",
  tipodebaja: "TipoDeBaja",
  provincia: "Provincia",
  nombredelasesor: "NombreDelAsesor",
  alta: "Altas",
  baja: "Baja",
  mail: "Mail",
  titular: "Titular",
  cuitcuil: "CuitCuil",
  banco: "Banco",
  tipodecuenta: "TipoDeCuenta",
  cbucvu: "CbuCvu",
  ndecuenta: "NDeCuenta",
  letracbu: "LetraCbu",
  largocbu: "LargoCbu",
  alias: "Alias",
  razonsocial: "RazonSocial",
  cuitrazonsocial: "CuitRazonSocial",
  estadoafip: "EstadoAfip",
  codactividad: "CodActividad",
  actividad: "Actividad",
  exento: "Exento",
  estadorentas: "EstadoRentas",
  actividaD2: "Actividad2",
  domiciliofiscal: "DomicilioFiscal",
  provinciA3: "Provincia3",
  alicuota: "Alicuota",
  codactividadrentas: "CodActividadRentas",
  actividadrentas: "ActividadRentas",
};

const colorMap = {
  blue: [
    /* "Id",*/
    "SasMutual",
    "Estado",
    "NDeTerminal",
    "NDeComercio",
    "NroLocal",
    "Legajo",
    "NombreDeFantasia",
    "Domicilio",
    "Localidad",
    "CodigoPostal",
    "Telefono",
    "LinkDePago",
    "Rubro",
    "RubroSecundario",
    "AltaAhora12",
    "FechaDeAlta",
    "FechaDeBaja",
    "TipoDeBaja",
    "Provincia",
    "NombreDelAsesor",
    "Altas",
    "Baja",
    "Mail",
  ],
  violet: [
    "Titular",
    "CuitCuil",
    "Banco",
    "TipoDeCuenta",
    "CbuCvu",
    "NDeCuenta",
    "LetraCbu",
    "LargoCbu",
    "Alias",
  ],
  yellow: [
    "RazonSocial",
    "CuitRazonSocial",
    "EstadoAfip",
    "CodActividad",
    "Actividad",
    "Exento",
    "EstadoRentas",
    "Actividad2",
    "DomicilioFiscal",
    "Provincia3",
    "Alicuota",
    "CodActividadRentas",
    "ActividadRentas",
  ],
};

const getBackgroundColor = (field) => {
  const mappedField = fieldMap[field] || field;
  if (colorMap.blue.includes(mappedField)) return "#B4C400"; // Primer color
  if (colorMap.violet.includes(mappedField)) return "#E89F2F"; // Segundo color
  if (colorMap.yellow.includes(mappedField)) return "#EFCA39"; // Último color
  return "white";
};

const estadoOptions = [
  { value: "ALTA", label: "ALTA" },
  { value: "BAJA", label: "BAJA" },
  { value: "DISPONIBLE", label: "DISPONIBLE" },
  { value: "ACREDITACION PENDIENTE", label: "ACREDITACION PENDIENTE" },
  { value: "SERVICIO.TECNICO", label: "SERVICIO.TECNICO" },
  { value: "EXTRAVIADA", label: "EXTRAVIADA" },
];

const altaOptions = [
  { value: "AGREGA", label: "AGREGA" },
  { value: "ALTA CCTT", label: "ALTA CCTT" },
  { value: "ALTA NUEVA", label: "ALTA NUEVA" },
  { value: "ALTA SSTT", label: "ALTA SSTT" },
];

const bajaOptions = [
  { value: "BAJA", label: "BAJA" },
  { value: "BAJA POR FACTURACION", label: "BAJA POR FACTURACION" },
  { value: "BAJA POR POSNET", label: "BAJA POR POSNET" },
  { value: "BAJA POR POSTET C", label: "BAJA POR POSTET C" },
  { value: "BAJA SSTT", label: "BAJA SSTT" },
  { value: "VERIFONE REUTILIZABLE", label: "VERIFONE REUTILIZABLE" },
];

const getInitialFieldValues = () => {
  const initialValues = {};
  Object.keys(fieldMap).forEach((key) => {
    initialValues[key] = ""; // Asigna valores iniciales vacíos
  });
  return initialValues;
};

const CRM = ({ datos = [], view }) => {
  const {
    legajos,
    comercios,
    rubros,
    provincias,
    asesores,
    bancos,
    loading: crmLoading, // Usar crmLoading para evitar conflictos con el estado interno
  } = useContext(CRMContext);

  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchTerms, setSearchTerms] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [loading, setLoading] = useState(false);
  const { darkMode } = useContext(DarkModeContext);

  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState({
    NDeComercio: "",
    Legajo: "",
    NombreDeFantasia: "",
    Domicilio: "",
    Localidad: "",
    codigoPostal: "",
    Telefono: "",
    Rubro: "",
    RubroSecundario: "",
    Mail: "",
    Razonsocial: "",
    CuitRazonSocial: "",
    EstadoAfip: "",
    CodActividad: "",
    Actividad: "",
    Exento: "",
    EstadoRentas: "",
    Actividad2: "",
    DomicilioFiscal: "",
    Provincia3: "",
    Alicuota: "",
    CodActividadRentas: "",
    ActividadRentas: "",
    Titular: "", // Nombre del titular
    CuitCuil: "", // CUIT/CUIL del titular
    Banco: "", // Nombre del banco
    TipoDeCuenta: "", // Tipo de cuenta (Ahorro, Corriente, etc.)
    CbuCvu: "", // CBU o CVU
    NDeCuenta: "", // Número de cuenta
    LetraCbu: "", // Letra del CBU
    LargoCbu: "", // Largo del CBU (cantidad de caracteres)
    Alias: "", // Alias del CBU
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const [showNewModal, setShowNewModal] = useState(false);
  const [terminalBaja, setTerminalBaja] = useState("");
  const [terminalAlta, setTerminalAlta] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [selectMotivo, setMotivo] = useState("");
  const [observacion, setObservacion] = useState("");
  const [tipoOperacion, setTipoOperacion] = useState("alta"); // Estado independiente para Tipo
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showHistorialModal, setShowHistorialModal] = useState(false);
  const [historialData, setHistorialData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loadingHistorial, setLoadingHistorial] = useState(false);
  

  // const [legajos, setLegajos] = useState([]);
  // const [comercios, setComercios] = useState([]);
  // const [rubros, setRubros] = useState([]);
  const [altaAhora12Options] = useState([
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
  ]);
  const [linkDePagoOptions] = useState([
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
  ]);


  // const [provincias, setProvincias] = useState([]);
  // const [asesores, setAsesores] = useState([]);
  // const [bancos, setBancos] = useState([]);
  const tipoCuentaOptions = [
    { value: "CAJA AHORRO", label: "CAJA AHORRO" },
    { value: "CUENTA CORRIENTE", label: "CUENTA CORRIENTE" },
    { value: "BILLETERA VIRTUAL", label: "BILLETERA VIRTUAL" },
  ];


  const [selectedTerminalForCopy, setSelectedTerminalForCopy] = useState(null);

  // Función que toma los datos de la terminal seleccionada y llena los campos
  
  
  const formatDateInput = (value) => {
    const digitsOnly = value.replace(/\D/g, "");
    const charArray = digitsOnly.split("");
    if (charArray.length > 4) {
      charArray.splice(4, 0, "/");
    }
    if (charArray.length > 7) {
      charArray.splice(7, 0, "/");
    }
    return charArray.join("");
  };

  const handleDateChange = (field, value) => {
    const formattedValue = formatDateInput(value);
    setModalData((prevData) => ({ ...prevData, [field]: formattedValue }));
  };
  useEffect(() => {
    const selectedProvince = provincias.find(
      (option) => option.value === modalData.provincia3
    );
    if (selectedProvince) {
      setModalData((prevData) => ({
        ...prevData,
        alicuota: selectedProvince.alicuota,
      }));
    }
  }, [modalData.provincia3, provincias]);
  // Modificar getTerminalOptions para manejar la lógica de incluir o excluir estados
  const getTerminalOptions = useMemo(() => {
    return datos
      .filter((dato) => {
        if (selectValue === "option5") {
          return dato.estado === "BAJA";
        }
        return true;
      })
      .map((dato) => ({
        value: dato.ndeterminal,
        label: `${dato.ndeterminal} - ${dato.estado}`,
      }));
  }, [datos, selectValue]);
  // const getTerminalOptions = (includeEstados, excludeEstado = false) => {
  //   return datos
  //     .filter(
  //       (dato) =>
  //         excludeEstado
  //           ? !includeEstados.includes(dato.estado) // Excluir los estados indicados
  //           : includeEstados.includes(dato.estado) // Incluir los estados indicados
  //     )
  //     .map((dato) => ({ value: dato.ndeterminal, label: dato.ndeterminal }));
  // };

  const handleTerminalSelection = useCallback(
    (option) => {
      setTerminalBaja(option ? option.value : "");

      const selectedTerminalData = datos.find(
        (terminal) => terminal.ndeterminal === option.value
      );

      if (selectedTerminalData) {
        setModalData({
          NDeComercio: selectedTerminalData.ndecomercio?.toString() || "", // Convertido a string
          Legajo: selectedTerminalData.legajo?.toString() || "",
          NombreDeFantasia:
            selectedTerminalData.nombredefantasia?.toString() || "",
          Domicilio: selectedTerminalData.domicilio?.toString() || "",
          Localidad: selectedTerminalData.localidad?.toString() || "",
          codigoPostal: selectedTerminalData.codigoPostal?.toString() || "", // Convertido a string
          Telefono: selectedTerminalData.telefono?.toString() || "", // Convertido a string
          Rubro: selectedTerminalData.rubro?.toString() || "",
          RubroSecundario:
            selectedTerminalData.rubrosecundario?.toString() || "",
          Mail: selectedTerminalData.mail?.toString() || "",
          Razonsocial: selectedTerminalData.razonsocial?.toString() || "",
          CuitRazonSocial:
            selectedTerminalData.cuitrazonsocial?.toString() || "", // Convertido a string
          EstadoAfip: selectedTerminalData.estadoafip?.toString() || "",
          CodActividad: selectedTerminalData.codactividad?.toString() || "", // Convertido a string
          Actividad: selectedTerminalData.actividad?.toString() || "",
          Exento: selectedTerminalData.exento?.toString() || "",
          EstadoRentas: selectedTerminalData.estadorentas?.toString() || "",
          Actividad2: selectedTerminalData.actividaD2?.toString() || "",
          DomicilioFiscal:
            selectedTerminalData.domiciliofiscal?.toString() || "",
          Provincia3: selectedTerminalData.provinciA3?.toString() || "",
          Alicuota: selectedTerminalData.alicuota?.toString() || "", // Convertido a string
          CodActividadRentas:
            selectedTerminalData.codactividadrentas?.toString() || "", // Convertido a string
          ActividadRentas:
            selectedTerminalData.actividadrentas?.toString() || "",
          Titular: selectedTerminalData.titular?.toString() || "",
          CuitCuil: selectedTerminalData.cuitcuil?.toString() || "", // Convertido a string
          Banco: selectedTerminalData.banco?.toString() || "",
          TipoDeCuenta: selectedTerminalData.tipodecuenta?.toString() || "",
          CbuCvu: selectedTerminalData.cbucvu?.toString() || "",
          NDeCuenta: selectedTerminalData.ndecuenta?.toString() || "", // Convertido a string
          LetraCbu: selectedTerminalData.letracbu?.toString() || "",
          LargoCbu: selectedTerminalData.largocbu?.toString() || "", // Convertido a string
          Alias: selectedTerminalData.alias?.toString() || "",
          FechaBaja: selectedTerminalData.fechaBaja?.toString() || "",
          FechaAlta: selectedTerminalData.fechaAlta?.toString() || "",
        });
      }
    },
    [datos]
  );

  useEffect(() => {
    // fetch("/api/legajo/lista")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setLegajos(
    //       data.map((item) => ({ value: item.nroLegajo, label: item.nroLegajo }))
    //     );
    //   });
    // fetch("/api/Comercio/lista")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setComercios(
    //       data.map((item) => ({ value: item.num, label: item.numComercio }))
    //     );
    //   });
    // fetch("/api/comercio/listarubros")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setRubros(data.map((item) => ({ value: item, label: item })));
    //   });
    // fetch("/api/provincia/listaprovincia")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setProvincias(
    //       data.map((item) => ({
    //         value: item.provincia,
    //         label: item.provincia,
    //         alicuota: item.alicuota,
    //       }))
    //     );
    //   });
    // fetch("/api/usuarios/obtenergestoresyasesores")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setAsesores(data.map((item) => ({ value: item, label: item })));
    //   });
    // fetch("/api/Banco/lista")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setBancos(
    //       data.map((item) => ({ value: item.nomBanco, label: item.nomBanco }))
    //     );
    //   });
  }, []);

  const handleSelectorChange = (selectedOption) => {
    setSearchField(selectedOption.value);
    setSearchValue("");
  };

  const handleSearchSubmit = () => {
    if (searchField) {
      setLoading(true);
      setTimeout(() => {
        setSearchTerms({ field: searchField, value: searchValue });
        setShowAll(false);
        setLoading(false);
      }, 1000);
    }
  };

  const handleResetSearch = () => {
    setSearchField("");
    setSearchValue("");
    setSearchTerms(null);
    setShowAll(false);
  };

  const handleShowAll = () => {
    setLoading(true);
    setTimeout(() => {
      setSearchTerms(null);
      setShowAll(true);
      setLoading(false);
    }, 1000);
  };

  const filteredDatos = datos.filter((dato) => {
    if (!searchTerms) return false;

    const { field, value } = searchTerms;

    return (
      value === "" || (dato[field] && dato[field].toString().includes(value))
    );
  });

  const selectOptions = [
    { value: "ndeterminal", label: "N° Terminal" },
    { value: "ndecomercio", label: "N° Comercio" },
    { value: "cuitcuil", label: "CUIT/CUIL" },
    //{ value: "cuitrazonsocial", label: "Cuit razón social" },
    { value: "estado", label: "Estado" },
    { value: "provincia", label: "Provincia" },
    { value: "localidad", label: "Localidad" },
  ];

  const handleShowModal = (
    dato = getInitialFieldValues(),
    editMode = true,
    tipo = "alta"
  ) => {
    setModalData(dato); // Actualiza los datos del modal
    setIsEditMode(editMode);
    setTipoOperacion(tipo); // Establecer el tipo (alta o editar) de manera independiente
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData({});
  };

  const handleInputChange = (field, value) => {
    setModalData((prevData) => ({
      ...prevData,
      [field]: value,
      Tipo: prevData.Tipo || "alta", // Mantén siempre el valor de 'Tipo'
    }));
  };

  const handleSaveChanges = async () => {
    try {
      setIsSubmitting(true);
      const token = sessionStorage.getItem("token");

      const dataToSend = { token };

      // Mapeamos todas las claves de modalData usando el fieldMap
      Object.keys(modalData).forEach((key) => {
        const mappedKey = fieldMap[key]; // Mapeamos cada clave
        if (mappedKey) {
          dataToSend[mappedKey] =
            modalData[key] !== null ? modalData[key].toString() : "";
        }
      });

      // Añadir el valor de 'Tipo' desde la variable tipoOperacion o un estado
      dataToSend.Tipo = tipoOperacion || "alta"; // Si 'tipoOperacion' no está definido, se usa "alta" como valor por defecto

      // Determinar la URL según el tipo de operación
      const apiUrl =
        view === "alta"
          ? "/api/altas/editarcrmalta"
          : "/api/bajas/editarcrmbajas";

      // Enviar los datos mediante fetch
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      // Manejo de errores
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      setIsSubmitting(false);

      // Si todo está bien, cerrar el modal y mostrar mensaje de éxito
      handleCloseModal();
      Swal.fire({
        icon: "success",
        title: "Cambios guardados",
        text: "Los cambios han sido guardados exitosamente.",
      }).then(() => {
        window.location.reload();
      });
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error al guardar los cambios:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los cambios no pudieron ser guardados.",
      });
    }
  };
  const handleOpenHistorialModal = async (id)=>{
    const token=sessionStorage.getItem("token")
    try {
      setSelectedId(id)
      setLoadingHistorial(true)
      setShowHistorialModal(true)
      console.log({id,token})
     

      const response= await fetch("api/historial/listahistorialcrmporid",{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body:JSON.stringify({
          token: token, // Aquí debe ser un campo de tipo string
          Id: selectedId.toString(),       // El id correspondiente
        }),
      })
        if (!response.ok) {
            const data = await response.json()

        const errorText= await response.text
        throw new Error(`Error: ${response.status} - ${errorText}`)

      }
      const data= await response.json()
      console.log(data)
      setHistorialData(data)
      setLoadingHistorial(false)
    } catch (error) {
      console.error("Error al obtener el historial:", error);
      setLoadingHistorial(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo cargar el historial.",
      });
      
    }
  }
  const handleCloseHistorialModal = () => {
    setShowHistorialModal(false);
    setHistorialData([]);
  };

  const handleNewModalSubmit = async () => {
    if (selectValue === "default" || !selectValue) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debe seleccionar una opción válida antes de enviar.",
      });
      return; // Salir de la función para evitar enviar el formulario
    }
    if (terminalAlta) {
      const terminal = datos.find((dato) => dato.ndeterminal === terminalAlta);

      // Si la terminal ya está en ALTA, pero la operación NO es Desvinculación ni Cambio de titularidad, lanza error
      //   if (
      //     terminal &&
      //     terminal.estado === "ALTA" &&
      //     !(selectValue === "option5" || selectValue === "option6")
      //   ) {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Error",
      //       text: "La terminal seleccionada ya está en estado 'ALTA' y no puede darse de alta nuevamente.",
      //     });
      //     return; // Salir de la función para evitar enviar el formulario
      //   }
      // }

      if (selectValue === "option1" && terminalBaja) {
        const terminal = datos.find(
          (dato) => dato.ndeterminal === terminalBaja
        );

        // // Si la terminal ya está en BAJA, pero la operación NO es Desvinculación ni Cambio de titularidad, lanza error
        // if (
        //   terminal &&
        //   terminal.estado === "BAJA" &&
        //   !(selectValue === "option5" || selectValue === "option6")
        // ) {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error",
        //     text: "La terminal seleccionada ya está en estado 'BAJA' y no puede darse de baja nuevamente.",
        //   });
        //   return; // Salir de la función para evitar enviar el formulario
      }
    }
    if (selectValue === "option5" && terminalAlta) {
      const terminal = datos.find((dato) => dato.ndeterminal === terminalAlta);

      if (terminal && terminal.estado === "ALTA") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No puede seleccionar una terminal en estado 'ALTA' para la opción de Desvinculación.",
        });
        return; // Salir de la función para evitar enviar el formulario
      }
    }
    setIsSubmitting(true);
    const apiMap = {
      option1: "/api/abm/baja",
      option2: "/api/abm/sstt",
      option3: "/api/abm/ssttcambio",
      option4: "/api/abm/bajaycambioclover",
      option5: "/api/abm/desvinculacion", // Nueva opción
      option6: "/api/abm/cambiotitularidad", // API para cambio de titularidad
    };

    const apiUrl = apiMap[selectValue];
    const token = sessionStorage.getItem("token");

    const dataToSend = {
      Token: token,
      Tipo: selectValue,
      Observacion: observacion,
      NumeroTerminalBaja: terminalBaja ? terminalBaja.toString() : "",
      NumeroTerminalAlta: terminalAlta ? terminalAlta.toString() : "",
      Motivo: selectMotivo,
      FechaBaja: modalData.FechaBaja || "", // Asegúrate de incluir la fecha de baja
      FechaAlta: modalData.FechaAlta || "", // Asegúrate de incluir la fecha de alta
    };

    // Si es opción 6, agrega los datos de titularidad
    if (selectValue === "option6") {
      Object.assign(dataToSend, {
        Titular: modalData.Titular || "", // Nombre del titular
        CuitCuil: modalData.CuitCuil || "", // CUIT/CUIL del titular
        Banco: modalData.Banco || "", // Nombre del banco
        TipoDeCuenta: modalData.TipoDeCuenta || "", // Tipo de cuenta
        CbuCvu: modalData.CbuCvu || "", // CBU o CVU
        NDeCuenta: modalData.NDeCuenta || "", // Número de cuenta
        LetraCbu: modalData.LetraCbu || "", // Letra del CBU
        LargoCbu: modalData.LargoCbu ? parseInt(modalData.LargoCbu, 10) : null, // Largo del CBU
        Alias: modalData.Alias || "", // Alias del CBU
        NDeComercio: modalData.NDeComercio || "", // Número de comercio
        Legajo: modalData.Legajo?.toString() || "", // **Convertir Legajo a string**
        NombreDeFantasia: modalData.NombreDeFantasia || "", // Nombre de fantasía
        Domicilio: modalData.Domicilio || "", // Domicilio
        Localidad: modalData.Localidad || "", // Localidad
        codigoPostal: modalData.codigoPostal || "", // Código postal
        Telefono: modalData.Telefono || "", // Teléfono
        Rubro: modalData.Rubro || "", // Rubro
        RubroSecundario: modalData.RubroSecundario || "", // Rubro secundario
        Mail: modalData.Mail || "", // Correo electrónico
        Razonsocial: modalData.Razonsocial || "", // Razón social
        CuitRazonSocial: modalData.CuitRazonSocial || "", // CUIT de la razón social
        EstadoAfip: modalData.EstadoAfip || "", // Estado en AFIP
        CodActividad: modalData.CodActividad || "", // Código de actividad
        Actividad: modalData.Actividad || "", // Actividad económica
        Exento: modalData.Exento || "", // Exento
        EstadoRentas: modalData.EstadoRentas || "", // Estado en Rentas
        Actividad2: modalData.Actividad2 || "", // Actividad secundaria
        DomicilioFiscal: modalData.DomicilioFiscal || "", // Domicilio fiscal
        Provincia3: modalData.Provincia3 || "", // Provincia
        Alicuota: modalData.Alicuota || "", // Alícuota
        CodActividadRentas: modalData.CodActividadRentas || "", // Código de actividad en Rentas
        ActividadRentas: modalData.ActividadRentas || "", // Actividad en Rentas
      });
    }

    console.log(dataToSend);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      setIsSubmitting(false);
      handleCloseNewModal();
      Swal.fire({
        icon: "success",
        title: "Datos guardados",
        text: "Los datos han sido guardados exitosamente.",
      }).then(() => {
        window.location.reload();
      });
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error al enviar los datos:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Los datos no pudieron ser guardados.",
      });
    }
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
    setTerminalBaja("");
    setTerminalAlta("");
    setSelectValue("");
    setObservacion("");
    setMotivo("");
  };

  const handleTerminalAltaChange = (selectedOption) => {
    if (selectedOption) {
      const terminal = datos.find(
        (datos) => datos.ndeterminal === selectedOption.value
      );
      if (terminal) {
        const newData = {};
        Object.keys(fieldMap).forEach((key) => {
          newData[key] = terminal[key] || "";
        });
        setModalData(newData);
      }
      setTerminalAlta(selectedOption.value);
    } else {
      setTerminalAlta("");
      setModalData(getInitialFieldValues());
    }
  };

  const hasLetters = (str) => {
    return /[a-zA-Z]/.test(str);
  };
  const handleCopyFromTerminal = () => {
    const terminalData = datos.find(
      (dato) => dato.ndeterminal === selectedTerminalForCopy
    );
  
    if (terminalData) {
      const updatedData = {};
      Object.keys(modalData).forEach((key) => {
        // Excluir los campos "id", "ndeterminal", y "ndecomercio"
        if (!["id", "ndeterminal", "ndecomercio"].includes(key)) {
          updatedData[key] = terminalData[key] || "";
        }
      });
  
      // Actualizar los datos del modal excepto los campos excluidos
      setModalData((prevData) => ({
        ...prevData,
        ...updatedData,
      }));
    } else {
      console.log("No se encontró la terminal seleccionada.");
    }
  };

  const renderField = (key) => {
    const displayName = fieldMap[key] || key;
    const value = modalData[key] || "";
    const commonProps = {
      key,
      controlId: key,
      className: "mb-3",
    };
    switch (key) {
      case "sasmutual":
      case "id":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control type="text" value={value} readOnly />
          </Form.Group>
        );
      case "ndeterminal":
        if (!isEditMode) {
          const terminalOptions = datos
            .filter((datos) => datos.estado !== "ALTA")
            .map((datos) => ({
              value: datos.ndeterminal,
              label: datos.ndeterminal,
            }));
          return (
            <Form.Group {...commonProps}>
              <Form.Label>{displayName}</Form.Label>
              <Select
                value={
                  terminalOptions.find((option) => option.value === value) ||
                  null
                }
                onChange={handleTerminalAltaChange}
                options={terminalOptions}
                isClearable
              />
            </Form.Group>
          );
        }
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control type="text" value={value} readOnly />
          </Form.Group>
        );
      case "estado":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            {isEditMode ? (
              <Form.Control
                readOnly
                value={
                  estadoOptions.find((option) => option.value === value)
                    ?.label || ""
                }
              />
            ) : (
              <Select
                value={estadoOptions.find((option) => option.value === value)}
                onChange={(selectedOption) =>
                  handleInputChange(key, selectedOption.value)
                }
                options={estadoOptions}
              />
            )}
          </Form.Group>
        );

      case "legajo":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={legajos.find((option) => option.value === value)}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={legajos}
            />
          </Form.Group>
        );
      case "ndecomercio":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                comercios.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={comercios}
            />
          </Form.Group>
        );
      case "nroLocal":
      case "codigoPostal":
      case "telefono":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              maxLength={key === "nrolocal" ? 4 : 15}
              pattern={
                key === "telefono" || key === "codigopostal"
                  ? "[0-9]*"
                  : undefined
              }
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "linkdepago":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={linkDePagoOptions.find((option) => option.value === value)}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={linkDePagoOptions}
            />
          </Form.Group>
        );
      case "rubro":
      case "rubrosecundario":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={rubros.find((option) => option.value === value) || null}
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={rubros}
              isClearable
            />
          </Form.Group>
        );
      case "altaahorA12":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={altaAhora12Options.find(
                (option) => option.value === value
              )}
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={altaAhora12Options}
            />
          </Form.Group>
        );
      case "fechadealta":
      case "fechadebaja":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value || ""}
              onChange={(e) => handleDateChange(key, e.target.value)}
              placeholder="YYYY/MM/DD"
              maxLength={10}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "provincia":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                provincias.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={provincias}
              isClearable
            />
          </Form.Group>
        );
      case "nombredelasesor":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                asesores.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={asesores}
              isClearable
            />
          </Form.Group>
        );
      case "alta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                altaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={altaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "baja":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                bajaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={bajaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "mail":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="email"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "cuitcuil":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "banco":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                bancos.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(key, selectedOption.value)
              }
              options={bancos}
              isClearable
            />
          </Form.Group>
        );
      case "tipodecuenta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                tipoCuentaOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) => {
                handleInputChange(key, selectedOption.value);
                handleInputChange(
                  "cbucvu",
                  selectedOption.value === "BILLETERA VIRTUAL" ? "CVU" : "CBU"
                );
              }}
              options={tipoCuentaOptions}
              isClearable
            />
          </Form.Group>
        );
      case "cbucvu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "ndecuenta":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                handleInputChange(key, newValue);
                handleInputChange(
                  "letracbu",
                  hasLetters(newValue) ? "CORRECTO" : "INCORRECTO"
                );
                handleInputChange("largocbu", newValue.length);
              }}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "letracbu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "largocbu":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "cuitrazonsocial":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "estadoafip":
        const estadoAfipOptions = [
          { value: "EXENTO", label: "EXENTO" },
          { value: "INHABILITADO", label: "INHABILITADO" },
          { value: "MONOTRIBUTISTA", label: "MONOTRIBUTISTA" },
          { value: "RESPONSABLE INSCRIPTO", label: "RESPONSABLE INSCRIPTO" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                estadoAfipOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={estadoAfipOptions}
              isClearable
            />
          </Form.Group>
        );
      case "codactividad":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "exento":
        const exentoOptions = [
          { value: "NO", label: "NO" },
          { value: "EXENTO GANANCIA", label: "EXENTO GANANCIA" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                exentoOptions.find((option) => option.value === value) || {
                  value,
                  label: value,
                }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={exentoOptions}
              isClearable
            />
          </Form.Group>
        );
      case "estadorentas":
        const estadoRentasOptions = [
          { value: "ALTA EN PROCESO", label: "ALTA EN PROCESO" },
          { value: "CM", label: "CM" },
          { value: "EXENTO", label: "EXENTO" },
          { value: "INSCRIPTO", label: "INSCRIPTO" },
          { value: "NO INSCRIPTO", label: "NO INSCRIPTO" },
        ];
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                estadoRentasOptions.find(
                  (option) => option.value === value
                ) || { value, label: value }
              }
              onChange={(selectedOption) =>
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={estadoRentasOptions}
              isClearable
            />
          </Form.Group>
        );
      case "actividaD2":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
      case "provinciA3":
        const selectedProvincia = provincias.find(
          (option) => option.value === value
        ) || { value, label: value };
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Select
              value={
                isEditMode && selectedProvincia.value !== undefined
                  ? selectedProvincia
                  : { value, label: value }
              }
              onChange={(selectedOption) => {
                handleInputChange(
                  key,
                  selectedOption ? selectedOption.value : ""
                );
                const selectedAlicuota =
                  provincias.find(
                    (option) => option.value === selectedOption?.value
                  )?.alicuota || "";
                handleInputChange("alicuota", selectedAlicuota);
              }}
              options={provincias}
              isClearable
            />
          </Form.Group>
        );

      case "alicuota":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              readOnly
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );

      case "codactividadrentas":
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="number"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              //style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );

      default:
        return (
          <Form.Group {...commonProps}>
            <Form.Label>{displayName}</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              // style={{ backgroundColor: getBackgroundColor(key) }}
            />
          </Form.Group>
        );
    }
  };
  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  // Verificar si el campo seleccionado es Provincia o Localidad
  const isProvinciaLocalidadOrEstado = (field) => {
    return field === "provincia" || field === "localidad" || field === "estado";
  };

  return (
    <>
      <div className="container" style={{ marginBottom: "10px" }}>
        <Row className="d-flex align-items-center justify-content-between">
          <Col md="auto">
            <Form.Label className="fs-12 lato-bold mb-0">
              Seleccionar campo de búsqueda
            </Form.Label>
          </Col>
          <Col>
            <Select
              className="select__control_custom-inicio select__control_custom lato-bold fs-12"
              classNamePrefix="select"
              value={selectOptions.find(
                (option) => option.value === searchField
              )}
              onChange={handleSelectorChange}
              options={selectOptions}
              isSearchable={true} // Asegura que el select sea buscable
              filterOption={
                isProvinciaLocalidadOrEstado(searchField)
                  ? customFilterOption
                  : undefined
              }
            />
          </Col>
          <Col md="auto" className="d-flex align-items-center">
            {/* Deshabilitar input hasta que se seleccione un campo */}
            <FormControl
              className="form-control input-ingresar-buscador-crm px-5 border-0"
              placeholder={`Buscar por ${
                selectOptions.find((option) => option.value === searchField)
                  ?.label || "Seleccione un campo"
              }`}
              aria-label={`Buscar por ${searchField}`}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              disabled={!searchField} // Deshabilitar si no hay campo de búsqueda seleccionado
            />
            {/* Deshabilitar botón de búsqueda hasta que se seleccione un campo */}
            <Button
              className="btn-publicar border-0 mx-1"
              onClick={handleSearchSubmit}
              disabled={!searchField} // Deshabilitar si no hay campo de búsqueda seleccionado
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>

            <Button
              className="btn-publicar border-0 mx-1"
              onClick={() => handleShowModal({}, false, "alta")}
              //  disabled={!searchField} // Deshabilitar si no hay campo de búsqueda seleccionado
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>

            <Button
              className="btn-publicar border-0 mx-1"
              onClick={() => setShowNewModal(true)}
              // disabled={!searchField} // Deshabilitar si no hay campo de búsqueda seleccionado
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Col>
        </Row>
      </div>

      <section style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="container">
          {loading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : // : !searchTerms && !showAll ? (
          //   <Card className={darkMode ? "my-3 text-black" : "my-3"}>
          //     <Card.Body
          //       className={
          //         darkMode ? "bg-dark text-white border-0" : "text-dark"
          //       }
          //     >
          //       <Card.Text className="lato-bold fs-16">
          //         <strong>Cómo realizar la búsqueda:</strong>
          //         <br />
          //         1. Utiliza el selector para elegir el campo que quieres
          //         buscar: N° de terminal, N° de comercio, CUIT-CUIL, o CUIT
          //         Razón Social.
          //         <br />
          //         2. Aparecerá un campo de búsqueda donde podrás ingresar el
          //         valor que deseas buscar.
          //         <br />
          //         3. Escribe el valor en el campo de búsqueda y presiona el{" "}
          //         <strong>botón con el ícono de lupa</strong>.<br />
          //         4. Para ver todos los registros, presiona el{" "}
          //         <strong>botón con el ícono de ojo</strong>.<br />
          //         5. Para restablecer la búsqueda, presiona el{" "}
          //         <strong>botón con el ícono de retorno</strong>.
          //       </Card.Text>
          //     </Card.Body>
          //   </Card>
          // )
          (searchTerms && filteredDatos.length > 0) || showAll ? (
            (showAll ? datos : filteredDatos).map((dato, index) => (
              <Card
                key={index}
                className={`mb-3 ${
                  darkMode ? "bg-dark text-white" : "bg-light text-dark"
                }`}
              >
                <Card.Body>
                  <Row className="flex-nowrap align-items-stretch">
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(0, Math.ceil(Object.keys(dato).length / 4))
                          .map((key, i) => {
                            const displayName =
                              fieldMap[key.toLowerCase()] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {key.toLowerCase() === "id" && (
                                  <Row className="align-items-center">
                                    <Col>
                                      <strong>{displayName}:</strong>{" "}
                                      {dato[key]}
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        variant="primary"
                                        onClick={() => handleOpenHistorialModal(dato[key])}
                                        className="btn-publicar border-0"
                                        style={{
                                          padding: "0 10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faFileInvoice} />
                                      </Button>
                                    </Col>
                                    <Col xs="auto">
                                      <Button
                                        variant="primary"
                                        onClick={() =>
                                          handleShowModal(dato, true, "editar")
                                        }
                                        className="btn-publicar border-0"
                                        style={{
                                          padding: "0 10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                                {key !== "id" && (
                                  <>
                                    <strong>{displayName}:</strong> {dato[key]}
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(
                            Math.ceil(Object.keys(dato).length / 4),
                            Math.ceil((Object.keys(dato).length * 2) / 4)
                          )
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(
                            Math.ceil((Object.keys(dato).length * 2) / 4),
                            Math.ceil((Object.keys(dato).length * 3) / 4)
                          )
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                    <Col md={3} className="d-flex flex-column">
                      <div className="d-flex flex-column flex-grow-1">
                        {Object.keys(dato)
                          .slice(Math.ceil((Object.keys(dato).length * 3) / 4))
                          .map((key, i) => {
                            const displayName = fieldMap[key] || key;
                            return (
                              <div
                                key={i}
                                className="mb-2 p-2 flex-grow-1"
                                style={{
                                  backgroundColor: getBackgroundColor(key),
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <strong>{displayName}:</strong> {dato[key]}
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))
          ) : (
            searchTerms &&
            filteredDatos.length === 0 && (
              <Card className="mb-3 text-center">
                <Card.Body>
                  <Card.Text>
                    No se encontraron resultados para la búsqueda.
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          )}
        </div>
      </section>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Editar Datos" : "Agregar Alta"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(0, Math.ceil(Object.keys(fieldMap).length / 4))
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(
                    Math.ceil(Object.keys(fieldMap).length / 4),
                    Math.ceil((Object.keys(fieldMap).length * 2) / 4)
                  )
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(
                    Math.ceil((Object.keys(fieldMap).length * 2) / 4),
                    Math.ceil((Object.keys(fieldMap).length * 3) / 4)
                  )
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}
                    </div>
                  ))}
              </Col>
              <Col md={3}>
                {Object.keys(fieldMap)
                  .slice(Math.ceil((Object.keys(fieldMap).length * 3) / 4))
                  .map((key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: getBackgroundColor(key),
                        borderRadius: "8px",

                        textAlign: "left",
                      }}
                    >
                      {renderField(key)}

                    </div>
                    
                  ))}

                {/* Aquí verificamos si la opción seleccionada en el campo ALTAS es "AGREGA" */}
                {modalData.alta === "AGREGA" && (
                  <>
                    <Form.Group controlId="selectTerminalForCopy">
                      <Form.Label>Seleccionar terminal para copiar datos</Form.Label>
                      <Select
                        value={
                          datos
                            .filter((dato) => dato.estado)
                            .map((dato) => ({
                              value: dato.ndeterminal,
                              label: `${dato.ndeterminal} - ${dato.estado}`,
                            }))
                            .find((option) => option.value === selectedTerminalForCopy) || null
                        }
                        onChange={(option) => setSelectedTerminalForCopy(option?.value)}
                        options={datos
                          .filter((dato) => dato.estado)
                          .map((dato) => ({
                            value: dato.ndeterminal,
                            label: `${dato.ndeterminal} - ${dato.estado}`,
                          }))}
                        placeholder="Selecciona una terminal"

                        isClearable
                      />
                    </Form.Group>
                    <Button variant="primary"  onClick={()=>{handleCopyFromTerminal();
    setSelectedTerminalForCopy(null); // Resetea el valor del Select a default
  }}
  disabled={!selectedTerminalForCopy} >
                      Tomar datos de la terminal seleccionada
                    </Button>
                  </>
                )}
              
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" />
              </>
            ) : isEditMode ? (
              "Guardar Cambios"
            ) : (
              "Enviar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewModal} onHide={handleCloseNewModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Gestionar Terminales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="selectOption">
              <Form.Label>Acción a realizar</Form.Label>
              <Form.Control
                as="select"
                value={selectValue || "default"} // Por defecto selecciona "default"
                onChange={(e) => {
                  setSelectValue(e.target.value);
                  if (e.target.value === "option1") {
                    setTerminalAlta("");
                  }
                }}
              >
                <option value="default" disabled>
                  Por favor, seleccione una opción
                </option>
                <option value="option1">Baja</option>
                <option value="option2">SSTT</option>
                <option value="option3">SSTT Cambio de Terminal</option>
                <option value="option4">Baja Verifone y Cambio Clover</option>
                <option value="option5">Desvinculación</option>
                <option value="option6">Cambio Titularidad</option>{" "}
                {/* Nueva opción */}
              </Form.Control>
            </Form.Group>
            {/* Terminal a dar de baja - incluye "ALTA" y "SERVICIO.TECNICO" */}
            <Form.Group controlId="terminalBaja">
              <Form.Label>Terminal a dar de baja</Form.Label>
              <Select
                value={
                  getTerminalOptions.find(
                    (option) => option.value === terminalBaja
                  ) || null
                }
                onChange={handleTerminalSelection}
                options={getTerminalOptions}
                isClearable
              />
            </Form.Group>

            {/* Terminal a dar de alta - excluye "ALTA" */}
            <Form.Group controlId="terminalAlta">
              <Form.Label>Terminal a dar de alta</Form.Label>
              {selectValue === "option4" || selectValue === "option5" ? (
                <Form.Control
                  type="text"
                  value={terminalAlta}
                  onChange={(e) => setTerminalAlta(e.target.value)}
                />
              ) : (
                <Select
                  value={
                    getTerminalOptions.find(
                      (option) => option.value === terminalAlta
                    ) || null
                  }
                  onChange={(option) =>
                    setTerminalAlta(option ? option.value : "")
                  }
                  options={getTerminalOptions}
                  isClearable
                  isDisabled={
                    selectValue === "option1" ||
                    selectValue === "option2" ||
                    selectValue === "option6"
                  }
                />
              )}
            </Form.Group>
            {/* Observación */}
            <Form.Group controlId="observacion">
              <Form.Label>Observación</Form.Label>
              <Form.Control
                type="text"
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
              />
            </Form.Group>

            {/* Acción a realizar */}

            <Row>
              <Col md={4}>
                <FormGroup controlId="selectMotivo">
                  <FormLabel>Motivo de Baja</FormLabel>
                  <FormControl
                    as="select"
                    value={selectMotivo || ""}
                    onChange={(e) => setMotivo(e.target.value)}
                  >
                    {bajaOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              </Col>

              <Col md={4}>
                <Form.Group controlId="fechadebaja">
                  <Form.Label>Fecha de Baja</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.FechaBaja || ""}
                    onChange={(e) =>
                      handleDateChange("FechaBaja", e.target.value)
                    } // Usar handleDateChange
                    placeholder="YYYY/MM/DD"
                    maxLength={10}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="fechadealta">
                  <Form.Label>Fecha de Alta</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.FechaAlta || ""}
                    onChange={(e) =>
                      handleDateChange("FechaAlta", e.target.value)
                    } // Usar handleDateChange
                    placeholder="YYYY/MM/DD"
                    maxLength={10}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Campos adicionales para opción 6: Cambio Titularidad */}
            {/* Campos adicionales para opción 6: Cambio Titularidad */}
            {selectValue === "option6" && (
              <>
                <Form.Group controlId="Titular">
                  <Form.Label>Nombre del Titular</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Titular || ""}
                    onChange={(e) =>
                      handleInputChange("Titular", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="CuitCuil">
                  <Form.Label>CUIT/CUIL del Titular</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CuitCuil || ""}
                    onChange={(e) =>
                      handleInputChange("CuitCuil", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Banco">
                  <Form.Label>Nombre del Banco</Form.Label>
                  <Select
                    value={
                      bancos.find(
                        (option) => option.value === modalData.Banco
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "Banco",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={bancos}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="TipoDeCuenta">
                  <Form.Label>Tipo de Cuenta</Form.Label>
                  <Select
                    value={
                      tipoCuentaOptions.find(
                        (option) => option.value === modalData.TipoDeCuenta
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        "TipoDeCuenta",
                        selectedOption ? selectedOption.value : ""
                      );
                      handleInputChange(
                        "CbuCvu",
                        selectedOption.value === "BILLETERA VIRTUAL"
                          ? "CVU"
                          : "CBU"
                      );
                    }}
                    options={tipoCuentaOptions}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="CbuCvu">
                  <Form.Label>CBU o CVU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CbuCvu || ""}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="NDeCuenta">
                  <Form.Label>Número de Cuenta</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.NDeCuenta || ""}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      handleInputChange("NDeCuenta", newValue);
                      handleInputChange(
                        "LetraCbu",
                        hasLetters(newValue) ? "CORRECTO" : "INCORRECTO"
                      );
                      handleInputChange("LargoCbu", newValue.length);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="LetraCbu">
                  <Form.Label>Letra del CBU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.LetraCbu || ""}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="LargoCbu">
                  <Form.Label>Largo del CBU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.LargoCbu || ""}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="Alias">
                  <Form.Label>Alias del CBU</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Alias || ""}
                    onChange={(e) => handleInputChange("Alias", e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="NDeComercio">
                  <Form.Label>Número de Comercio</Form.Label>
                  <Select
                    value={
                      comercios.find(
                        (option) => option.value === modalData.NDeComercio
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "NDeComercio",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={comercios}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="Legajo">
                  <Form.Label>Legajo</Form.Label>
                  <Select
                    value={
                      legajos.find(
                        (option) => option.value === modalData.Legajo
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "Legajo",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={legajos}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="NombreDeFantasia">
                  <Form.Label>Nombre de Fantasía</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.NombreDeFantasia || ""}
                    onChange={(e) =>
                      handleInputChange("NombreDeFantasia", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Domicilio">
                  <Form.Label>Domicilio</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Domicilio || ""}
                    onChange={(e) =>
                      handleInputChange("Domicilio", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Localidad">
                  <Form.Label>Localidad</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Localidad || ""}
                    onChange={(e) =>
                      handleInputChange("Localidad", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="codigoPostal">
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.codigoPostal || ""}
                    onChange={(e) =>
                      handleInputChange("codigoPostal", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Telefono">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Telefono || ""}
                    onChange={(e) =>
                      handleInputChange("Telefono", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Rubro">
                  <Form.Label>Rubro</Form.Label>
                  <Select
                    value={
                      rubros.find(
                        (option) => option.value === modalData.Rubro
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "Rubro",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={rubros}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="RubroSecundario">
                  <Form.Label>Rubro Secundario</Form.Label>
                  <Select
                    value={
                      rubros.find(
                        (option) => option.value === modalData.RubroSecundario
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "RubroSecundario",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={rubros}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="Mail">
                  <Form.Label>Mail</Form.Label>
                  <Form.Control
                    type="email"
                    value={modalData.Mail || ""}
                    onChange={(e) => handleInputChange("Mail", e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="Razonsocial">
                  <Form.Label>Razón Social</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Razonsocial || ""}
                    onChange={(e) =>
                      handleInputChange("Razonsocial", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="CuitRazonSocial">
                  <Form.Label>CUIT Razón Social</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CuitRazonSocial || ""}
                    onChange={(e) =>
                      handleInputChange("CuitRazonSocial", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="EstadoAfip">
                  <Form.Label>Estado AFIP</Form.Label>
                  {/** Inicializamos las opciones dentro del Form.Group **/}
                  <Select
                    value={
                      [
                        { value: "EXENTO", label: "EXENTO" },
                        { value: "INHABILITADO", label: "INHABILITADO" },
                        { value: "MONOTRIBUTISTA", label: "MONOTRIBUTISTA" },
                        {
                          value: "RESPONSABLE INSCRIPTO",
                          label: "RESPONSABLE INSCRIPTO",
                        },
                      ].find(
                        (option) => option.value === modalData.EstadoAfip
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "EstadoAfip",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={[
                      { value: "EXENTO", label: "EXENTO" },
                      { value: "INHABILITADO", label: "INHABILITADO" },
                      { value: "MONOTRIBUTISTA", label: "MONOTRIBUTISTA" },
                      {
                        value: "RESPONSABLE INSCRIPTO",
                        label: "RESPONSABLE INSCRIPTO",
                      },
                    ]}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="CodActividad">
                  <Form.Label>Código de Actividad</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CodActividad || ""}
                    onChange={(e) =>
                      handleInputChange("CodActividad", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Actividad">
                  <Form.Label>Actividad</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Actividad || ""}
                    onChange={(e) =>
                      handleInputChange("Actividad", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Exento">
                  <Form.Label>Exento</Form.Label>
                  {/** Inicializamos las opciones dentro del Form.Group **/}
                  <Select
                    value={
                      [
                        { value: "NO", label: "NO" },
                        { value: "EXENTO GANANCIA", label: "EXENTO GANANCIA" },
                      ].find((option) => option.value === modalData.Exento) ||
                      null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "Exento",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={[
                      { value: "NO", label: "NO" },
                      { value: "EXENTO GANANCIA", label: "EXENTO GANANCIA" },
                    ]}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="EstadoRentas">
                  <Form.Label>Estado Rentas</Form.Label>
                  {/** Inicializamos las opciones dentro del Form.Group **/}
                  <Select
                    value={
                      [
                        { value: "ALTA EN PROCESO", label: "ALTA EN PROCESO" },
                        { value: "CM", label: "CM" },
                        { value: "EXENTO", label: "EXENTO" },
                        { value: "INSCRIPTO", label: "INSCRIPTO" },
                        { value: "NO INSCRIPTO", label: "NO INSCRIPTO" },
                      ].find(
                        (option) => option.value === modalData.EstadoRentas
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleInputChange(
                        "EstadoRentas",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={[
                      { value: "ALTA EN PROCESO", label: "ALTA EN PROCESO" },
                      { value: "CM", label: "CM" },
                      { value: "EXENTO", label: "EXENTO" },
                      { value: "INSCRIPTO", label: "INSCRIPTO" },
                      { value: "NO INSCRIPTO", label: "NO INSCRIPTO" },
                    ]}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="Actividad2">
                  <Form.Label>Actividad 2</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Actividad2 || ""}
                    onChange={(e) =>
                      handleInputChange("Actividad2", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="DomicilioFiscal">
                  <Form.Label>Domicilio Fiscal</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.DomicilioFiscal || ""}
                    onChange={(e) =>
                      handleInputChange("DomicilioFiscal", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="Provincia3">
                  <Form.Label>Provincia</Form.Label>
                  <Select
                    value={
                      provincias.find(
                        (option) => option.value === modalData.Provincia3
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        "Provincia3",
                        selectedOption ? selectedOption.value : ""
                      );
                      const selectedAlicuota =
                        provincias.find(
                          (option) => option.value === selectedOption?.value
                        )?.alicuota || "";
                      handleInputChange("Alicuota", selectedAlicuota);
                    }}
                    options={provincias}
                    isClearable
                  />
                </Form.Group>

                <Form.Group controlId="Alicuota">
                  <Form.Label>Alicuota</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.Alicuota || ""}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="CodActividadRentas">
                  <Form.Label>Código de Actividad Rentas</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.CodActividadRentas || ""}
                    onChange={(e) =>
                      handleInputChange("CodActividadRentas", e.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group controlId="ActividadRentas">
                  <Form.Label>Actividad Rentas</Form.Label>
                  <Form.Control
                    type="text"
                    value={modalData.ActividadRentas || ""}
                    onChange={(e) =>
                      handleInputChange("ActividadRentas", e.target.value)
                    }
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNewModal}>
            Cerrar
          </Button>
          <Button
            variant="success"
            onClick={handleNewModalSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm"></Spinner>
              </>
            ) : (
              "Enviar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
          <Modal show={showHistorialModal} onHide={handleCloseHistorialModal} size="lg">
              <Modal.Header closeButton>
                  <Modal.Title>Historial de la Terminal</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  {loadingHistorial ? (
                      <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                      </Spinner>
                  ) : (
                      <div className="container table-responsive py-3 px-5">
                          <Table responsive striped hover>
                              <thead className={darkMode ? "bg-dark py-2" : "bg-light py-2"}>
                                  <tr className="text-center tabla-thead">
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>ID</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Nro Terminal</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Fecha de Subida</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Nombre Comercio</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Tipo</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Observaciones</th>
                                      <th className={darkMode ? "bg-white text-dark fs-14 py-3" : "bg-dark text-white fs-14 py-3"}>Usuario Nombre</th>
                                  </tr>
                              </thead>
                              <tbody className="text-center">
                                  {historialData.map((item) => (
                                      <tr key={item.id} className={darkMode ? "bg-dark text-white" : "bg-white text-dark"}>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.id}</td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.bajaNroTerminal || 'N/A'}</td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>
                                              {item.fechaSubida ? new Date(item.fechaSubida).toLocaleDateString() : 'N/A'}
                                          </td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.nombrecomercio || 'N/A'}</td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.tipo || 'N/A'}</td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.observaciones || 'N/A'}</td>
                                          <td className={darkMode ? "fs-14 py-4 text-white" : "fs-14 py-4"}>{item.usuarioNombre || 'N/A'}</td>
                                      </tr>
                                  ))}
                              </tbody>
                          </Table>
                      </div>
                  )}
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseHistorialModal}>
                      Cerrar
                  </Button>
              </Modal.Footer>
          </Modal>

    </>
  );
};

export default CRM;
