// Importar hooks
import { useContext } from "react";

// Importar contexto
import { DarkModeContext } from "../context/DarkModeContext";

// Importar librerías de gráficos
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
//import 'chartjs-adapter-date-fns'; // Adaptador para manejar fechas

// Registrar componentes de ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale // Importar y registrar el TimeScale para las fechas
);

const GraficoTerminales = ({ data }) => {
  // Context para modo oscuro
  const { darkMode } = useContext(DarkModeContext);

  // Definir el color de las etiquetas basado en el modo oscuro
  const tickColor = darkMode ? "#fff" : "#292B2F";

  // Extraer las fechas de pago y los datos correspondientes a las terminales que facturaron
  const { fechasDePago, terminalesPorSemana } = data;

  // Datos para el gráfico de líneas
  const chartData = {
    labels: fechasDePago, // Fechas de pago en el eje X
    datasets: [
      {
        label: "Terminales que facturaron",
        data: terminalesPorSemana, // Cantidad de terminales por semana
        borderColor: darkMode ? "#4CAF50" : "#FFA500",
        backgroundColor: darkMode ? "rgba(76, 175, 80, 0.2)" : "rgba(255, 165, 0, 0.2)", // Fondo translúcido
        fill: true, // Rellena el área debajo de la línea
        tension: 0.3, // Suaviza la línea
        pointBackgroundColor: darkMode ? "#4CAF50" : "#FFA500", // Color de los puntos
      },
    ],
  };

  // Configuración de opciones del gráfico
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        bodyFont: {
          size: 12,
        },
        padding: 10,
        boxWidth: 6,
        boxHeight: 6,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y.toLocaleString(); // Formatea los números con comas
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time", // Escala de tiempo para fechas
        time: {
          unit: "week", // Mostrar semanas
          tooltipFormat: "dd/MM/yyyy", // Formato en el tooltip
          displayFormats: {
            week: "dd MMM", // Formato de fecha en el eje X
          },
        },
        ticks: {
          color: tickColor, // Ajusta el color de las etiquetas en el eje X
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: tickColor, // Ajusta el color de las etiquetas en el eje Y
        },
      },
    },
  };

  return (
    <section className="container py-analisis-grafica">
      <div className="row">
        <article className="col-12 col-lg-12 my-2">
          <div
            style={{ paddingTop: "0px", height: "100%" }}
            className={
              darkMode ? "bg-grafica-dark px-5 pb-4" : "bg-grafica px-5 pb-4"
            }
          >
            <h2 className="fs-18-a-16 py-4 text-center">Terminales que Facturaron por Semana</h2>
            <div className="d-flex justify-content-center">
              <Line className="mx-4 my-4" data={chartData} options={chartOptions} />
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default GraficoTerminales;
